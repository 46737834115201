<template>
    <div class="task-content">
        <div class="sub-item-box">
            <div class="sub-item-title-box">
                <div class="s-item-title-center">
                    <div class="header-duration-box medium-size">
                        <div class="bg-ellipse-box blue-b">
                            <div class="bg-ellipse-dot dot-left"></div>
                            <div class="bg-ellipse-dot dot-right"></div>
                            <div class="ellipse-text auto-width">
                                <span>任务提交</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="sub-item-content commit-box">
                <tinymce-editor id="tiny-commit" v-model="content" :init="tiny_init"></tinymce-editor>
                <div class="submit-btn-group">
                    <el-button @click="goBack">返回</el-button>
                    <el-button type="primary" @click="submitContent">提交</el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Editor from "@tinymce/tinymce-vue";
export default {
    name: "TaskCommit",
    components: {
        'tinymce-editor': Editor
    },
    props: {
        ids: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            content: '',
            tiny_init: {
                menubar: false,
                height: 400,
                plugins: "lists, advlist,fullscreen,table,link,image imagetools",
                toolbar: [
                    'bold italic underline strikethrough link|alignleft aligncenter alignright bullist numlist|image forecolor backcolor removeformat |table tabledelete|fullscreen|',
                    'fontsizeselect formatselect'
                ],
                language: 'zh_CN',
                images_upload_handler: function(blobInfo, success, failure, progress) {
                    let xhr, formData;
                    xhr = new XMLHttpRequest();
                    xhr.withCredentials = false;
                    xhr.open('POST', 'project/tinyImgUpload');
                    xhr.setRequestHeader("Authorization", localStorage.getItem('token'));

                    xhr.upload.onprogress = function(e) {
                        progress(e.loaded / e.total * 100);
                    }

                    xhr.onload = function() {
                        var json;
                        if (xhr.status == 403) {
                            failure('HTTP Error: ' + xhr.status, { remove: true });
                            return;
                        }
                        if (xhr.status < 200 || xhr.status >= 300) {
                            failure('HTTP Error: ' + xhr.status);
                            return;
                        }
                        json = JSON.parse(xhr.responseText);
                        if (!json || typeof json.location != 'string') {
                            failure('Invalid JSON: ' + xhr.responseText);
                            return;
                        }
                        success(json.location);
                    };

                    xhr.onerror = function() {
                        failure('Image upload failed due to a XHR Transport error. Code: ' + xhr.status);
                    }

                    formData = new FormData();
                    formData.append('file', blobInfo.blob(), blobInfo.filename());
                    xhr.send(formData);
                }
            }
        }
    },
    methods: {
        goBack() {
            this.$router.go(-1);
        },
        submitContent() {
            console.log(this.ids);
            console.log(this.content);
            if (/^<p>(&nbsp;|&nbsp; )+<\/p>$/.test(this.content) || this.content.length === 0) {
                this.$message.warning('任务内容不能为空');
                return;
            }
            let param = {
                content: this.content
            }
            if (this.ids.sx_id) {
                param.sx_id = this.ids.sx_id
            } else if (this.ids.project_id) {
                param.project_id = this.ids.project_id
            }

            this.$shttp.axiosPost(this.$api.drill_putDrillData, param, (res) => {
                if (res.code === 200) {
                    this.$message({
                        type: 'success',
                        message: res.msg,
                        duration: 1000
                    });
                }
            }, (err) => {
                this.$common.axiosErrorMsg(err);
            })
        }

    }
}
</script>

<style lang="scss" scoped>
    @import "./css/project_info";
    @import "./css/commit";
</style>